.divPlainMain {
	width: 1400px; 
	margin-left: auto; 
	margin-right: auto; 
	margin-bottom: 20px; 
	text-align: left;
}

.divPlainBody {
	padding: 10px;
	border: 1px solid gray; 
	background-color: rgb(240,240,240);
}
.divLabelWithTooltip {
	position: relative;
	display: inline-block;
}
.divLabelWithTooltip .tooltiptext {
	visibility: hidden;
	width: 250px;
	background-color: #eee;
	color: black;
	text-align: center;
	font-size: 12pt;
	font-weight: normal;
	font-style: normal;
	padding: 5px 10px;
	border: 1px solid black;
	border-radius: 10px;
	
	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	
	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.9s;
	transition-delay: 1s;
}
.divLabelWithTooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.inputFieldString {
	height: 25px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;	
	background-color: white;
}
.textArea {
	height: auto;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;	
}

.selectEnum {
	height: 31px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
}


.buttonIcon {
	width: 36px;
	height: 30px;
}
