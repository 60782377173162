.divDropDown {
	margin-left: 10px;
	position: relative;
	display: inline-block;	
}

.divDropDownBody {
	position: absolute;
	min-width: 300px;
	background-color: #f1f1f1;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	text-align: left;
	z-index: 2;	
}

.divDropDownItem {
	padding: 8px;
}

.divDropDownItem:hover {
	background-color: #ddd;
	cursor: pointer;
}

.divDropDownItemWithTooltip {
	padding: 8px;
	width: 280px;
   position: relative;
   display: inline-block;
}
.divDropDownItemWithTooltip:hover {
	background-color: #ddd;
	cursor: pointer;
}
.divDropDownItemWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divDropDownItemWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
