.divLoginForm {
	border: 1px solid green;
	width: 350px;
	margin-left: auto;
	margin-right:auto;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: #F0F0F0
}
.divHeader {
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 18pt;
}
.divParameter {
	display: flex;
	padding-left: 20px;
	margin-bottom: 10px;
}
.divParamLabel {
	width: 120px;
	text-align: left;
}
.divParamInput {
	width: 200px;	
}
.divButtons {
	text-align: right;
	padding-right: 20px;
}