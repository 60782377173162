.divHeader {
	font-size: 16pt;
	text-align: center;
	padding: 10px;
	border-radius: 21px 21px 0 0;
	background-color: #F0F0F0;
}
.divParameters {
	margin-top: 10px;
	padding: 10px;
}
.hiddenFile {
  display: none;
}
.divWaiting {
	text-align: center;
	padding: 7px;
}
.divMessage {
	margin:15px;	
	padding: 5px;
	border: 1px solid blue;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	height: 100px;
	overflow: scroll;
}
.areaMessage {
	margin: 20px;
	width: 90%;
}
.divButtons {
	margin-top: 7px;
	padding: 10px;
	margin-right: 15px;
	text-align: right;
}
