.divPlainTitle {
	width: 90%;
	text-align: left;
	font-weight: bold;
	margin-top: 3px;
	margin-left: auto;
	margin-right:auto;	
	margin-bottom: 3px;
}
.divFullDescription {
	width: 90%;
	margin-left: auto;
	margin-right:auto;
	margin-bottom: 10px;	
	background-color: rgb(200, 200, 200);
}
.divFieldGroup {
	text-align: left;
	border: 1px solid blue;
	margin-top: 10px;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	background-color: rgb(230, 230, 230);
}
.divFieldList {
	text-align: left;
	border: 1px solid blue;
	margin-top: 10px;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	background-color: rgb(200, 200, 200);
}
.tableLine {
	width: 100%;
}
.tdTableLineCell {
	padding-right: 20px;
}
.divSubfields {
	padding-top: 5px;
	text-align: left;
}
.divFieldScalar {
	display: flex;
	align-items: center;
}
.divFieldScalarLabel {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	padding-right: 5px;
	flex-shrink: 0;
}

.divGroupLabelWithTooltip {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	padding-right: 5px;
	flex-shrink: 0;
   position: relative;
   display: inline-block;
}
.divGroupLabelWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divGroupLabelWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.divFieldScalarLabelWithTooltip {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	padding-right: 5px;
	flex-shrink: 0;
   position: relative;
   display: inline-block;
}
.divFieldScalarLabelWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divFieldScalarLabelWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.divCheckBoxLabelWithTooltip {
	flex-shrink: 0;
   position: relative;
   display: inline-block;
}
.divCheckBoxLabelWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divCheckBoxLabelWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.divFieldScalarValue {
	flex-grow: 100;
}
.divFieldScalarValueWithButton {
	flex-grow: 100;
}
.divFieldScalarTools {
	width: 100px;
	flex-shrink: 0;
	padding-left: 10px;
}
.divFieldRefTools {
	display: flex;
	flex-grow: 1;
	margin-left: 10px;
}
.divFieldButton {
	margin-left: 10px;
}
.divFieldSystemData {
	display: flex;
	align-items: center;
	margin-top: 3px;
}
.divFieldLabelSystem {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	padding-right: 5px;
	width: 200px;	
}
.divFieldSystemBody {
	border: 1px solid blue;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
	padding: 5px;
	height: 600px;
	overflow: scroll;
}

.divFieldLine {
	display: flex;
	align-items: center;
	gap: 10px;
}
.divFieldBlock {
}

.inputFieldScalarValue {
	height: 25px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;	
	background-color: white;
}
.inputFieldScalarValueInvalid {
	height: 25px;
	width: calc(100% - 10px);
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;	
	background-color: LightYellow;
}
.textArea {
	height: auto;
	width: 100%;
	font-size: 12pt;
}

.buttonToolbarIcon {
	width: 36px;
	height: 30px;
}

.buttonToolbarText {
	height: 30px;
}


