.divHeader {
	font-size: 14pt;
	text-align: center;
	padding: 10px;
	border-radius: 21px 21px 0 0;
	background-color: #F0F0F0;
}
.divBody {
	font-size: 16pt;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 10px;
}