.tableTableView {
	table-layout: fixed;
}

.divColumnHeaderWithTooltip {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12pt;
   position: relative;
   display: inline-block;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
}
.divColumnHeaderWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divColumnHeaderWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
