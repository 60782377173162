.divWithTooltip {
	display: inline-block;
	position: relative;
}
.divWithTooltip .tooltiptext {
	visibility: hidden;
	width: 250px;
	background-color: #eee;
	color: black;
	text-align: center;
	padding: 5px 10px;
	border: 1px solid black;
	border-radius: 10px;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;

	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.9s;
	transition-delay: 1s;
}
.divWithTooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
