.divRateView {
	background-color: white;
	width: 100%;
}

.divControlPanel {
	position: sticky;
	top: 28px;
	left: 0;
	background-color: rgb(245,245,245);
	z-index: 1;
	opacity: 1;
	display: flex;
	padding: 10px 10px 10px 10px;
	width: calc(100%-20px);
}
.divButtonsModify {
	display: flex;
	margin-left: 30px;
}
.divButtonsSelect {
	margin-left: 100px;
}
.spanButtonSelect {
	font-weight: bold;
}
.divInfoPanel {
	margin-left: 50px;
}

.divRateViewBody {
	display: flex;
	width: 100%;
	margin: 5px 10px 5px 10px;
}
/*
	background-color: rgb(240, 240, 240);
*/

.divLeftSide {
	border: 1px solid blue;
	min-width: 300px;
	width: 300px;
	margin-right: 5px;
}

.divConditions {
	padding: 5px;
	width: calc(100% - 10px);
}
.divConditionHeader {
	display: flex;
	padding: 4px;
	background-color: lightgrey;
	align-items: center;
}
.divConditionTitle {
	flex-grow: 100;
	text-align: center;
	font-weight: bold;	
}
.divRowsTableTreeMode {
	text-align: left;
	padding-left: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.divTools {
	padding: 5px;
	width: calc(100% - 10px);
}

.divData {
	text-align: left;
}
/* 	
	padding: 5px;
	border: 1px solid green;
width: calc(100% - 315px); 
	flex-grow: 100;
	overflow-x: auto;
*/

.tableRateView {
}

.tdRateViewCondition {
	width: 300px;
}
.tdRateViewTable {
}

.divFilterLabelWithTooltip {
   display: inline-block;
   position: relative;
	width: calc(100% - 10px);
	padding-left: 3px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 10pt;
	text-align: left;
}
.divFilterLabelWithTooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: black;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.9s;
  transition-delay: 1s;
}
.divFilterLabelWithTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.divFilter {
	margin-top: 7px;
}
.divFilterLabel {
	width: calc(100% - 10px);
	text-align: left;
	padding-left: 10px;
	font-size: 10pt;
}
.divFilterInput {
	display: flex;
	width: calc(100% - 10px);
	text-align: left;
	padding-left: 10px;
}
.inputFilterValue {
	height: 25px;
	width: calc(100% - 10px);
	color: blue;
	font-size: 12pt;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}
.inputFilterPartValue {
	width: calc(45% - 10px);
	color: blue;
	font-size: 12pt;
}

.divFilterObjectRef {
	width: calc(100% - 13px);
	display: flex;
	text-align: left;
	margin-left: 10px;
	margin-right: 3px;
}
/*	flex-grow: 100; */



.inputObjectRefCard {
	flex-grow: 100;
	min-width: 30px;
	height: 25px;
	color: blue;
	font-size: 12pt;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}
.buttonObjectRefSelect {
	flex-grow: 1;
	margin-left: 7px;
	min-width: 36px;
	height: 30px;
}
.buttonObjectRefClear {
	flex-grow: 1;
	margin-left: 7px;
	min-width: 36px;
	height: 30px;
}

.buttonToolbarIcon {
	width: 36px;
	height: 30px;
}

.buttonToolbarText {
	height: 30px;
}
