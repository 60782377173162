.divMain {
	border: 1px solid black;
	background-color: rgb(240,240,240);
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 15px;
	text-align: left;
	padding: 15px;
}

.divPassword {
	width: 50%;
	padding: 10px;
	border: 1px solid blue;
}
.divPasswordInput {
}
.divPasswordInputItem {
	display: flex;
	margin-top: 10px;
	padding-left:20px;
}
.divPasswordInputItemLabel {
	width: 200px;
}
.inputPasswordItem {
}

.divAccessLevel {
	margin-top: 20px;
}
.divAccessLevelBlock {
	padding-top: 20px;
	padding-left: 40px;
}
.divAccessLevelBlockItem {
	padding-left: 40px;
}

th, td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}