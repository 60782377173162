.divMenuBar {
	position: sticky;
	top: 0;
	z-index: 2;
	text-align: left;
	background-color: rgb(230,230,230);
	display: flex;
}
.divMenu {
	margin-left: 10px;
	padding: 5px;
	font-size: 12pt;
	cursor: pointer;
}
.divMenu:hover {
	background-color: rgb(200,200,200);
}
.aLink {
	color: black;
	text-decoration: none;
}
.divDropdown {
	position: relative;
	margin-left: 10px;
	display: inline-block;
}
.buttonDropdown {
	padding: 5px;
	cursor: pointer;
	border: none;
	font-size: 12pt;
}
.divDropdownContent {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	opacity: 1;
	min-width: 260px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 3;
}
/* Links inside the dropdown */
.divDropdownContent a {
	color: black;
	text-decoration: none;
}
.divDropdownItem {
	padding: 8px 16px;
}
/* Change color of dropdown links on hover */
.divDropdownContent .divDropdownItem:hover {
	background-color: rgb(200,200,200);
}

/* Show the dropdown menu on hover */
.divDropdown:hover .divDropdownContent {display: block;}

/* Change the background color of the dropdown span when the dropdown content is shown */
.divDropdown:hover .spanDropdown {
	background-color: rgb(200,200,200);
}



.divNavBar {
	margin-top: 5px;
}

.divLinkRow {
	padding: 5px;
}

.navLinkActive {
	font-weight: bold;
}

.navLinkPassive {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	padding: 2px 15px 2px 15px;
	margin-right: 10px;
	margin-bottom: 2px;
	text-decoration: none;
	background-color: #F0F0F0;
	color: black;
}